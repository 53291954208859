import React, { Fragment } from 'react';
import { Row, Col, FormControl, InputGroup, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker'
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it"; // the locale you want
registerLocale("it", it); // register it with the name you want

export class FiltriAvanzati extends React.Component {
    // constructor(props){
    //     super(props)
    //     this.state = {
    //     }
    // }

    descriptionVisible = () => {
        if (this.props.filters.find((f) => f.name === 'tipologia_esito').value === "1" || this.props.filters.find((f) => f.name === 'tipologia_esito').value === "2" || this.props.filters.find((f) => f.name === 'tipologia_esito').value === "3") return true;

        return false;
    }


    render() {
        return (<Row>
            <Col>
                <Accordion id={'accordionAdv'} defaultActiveKey={this.props.defaultVisibleAccordion}>
                    <Accordion.Toggle as={'a'} onClick={this.props.handleAccordion} variant="link" eventKey="0">
                        <span className='ricercaAvanzata'>Ricerca avanzata <FontAwesomeIcon icon={this.props.icon} /></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" id={'accordionBody'}>
                        <Fragment>
                            <Row className='mt-2'>
                                <Col lg={3}>
                                    <label className='customLabel' htmlFor="basic-url">Data immatricolazione</label>
                                    {/* Datepicker */}
                                    <Row>
                                        <Col>
                                            <label className='labelRangeCalendar'>Da</label>
                                            <DatePicker
                                                className='datepicker'
                                                closeOnScroll={true}
                                                onChange={(e) => this.props.changeDateImm(e, 'S')}
                                                // minDate={}
                                                maxDate={new Date()}
                                                selected={this.props.filters.find(f => f.name === 'startDataImm').value}
                                                startDate={this.props.filters.find(f => f.name === 'startDataImm').value}
                                                endDate={this.props.filters.find(f => f.name === 'endDataImm').value}
                                                selectsStart
                                                showYearDropdown
                                                showMonthDropdown
                                                dateFormat="dd/MM/yyyy"
                                                locale="it"
                                            />
                                        </Col>
                                        <Col>
                                            <label className='labelRangeCalendar'>A</label>
                                            <DatePicker
                                                className='datepicker'
                                                closeOnScroll={true}
                                                onChange={(e) => this.props.changeDateImm(e, 'E')}
                                                selected={this.props.filters.find(f => f.name === 'endDataImm').value}
                                                startDate={this.props.filters.find(f => f.name === 'startDataImm').value}
                                                endDate={this.props.filters.find(f => f.name === 'endDataImm').value}
                                                minDate={this.props.filters.find(f => f.name === 'startDataImm').value}
                                                maxDate={new Date()}
                                                selectsEnd
                                                showYearDropdown
                                                showMonthDropdown
                                                dateFormat="dd/MM/yyyy"
                                                locale="it"
                                            />
                                        </Col>

                                    </Row>
                                </Col>
                                <Col lg={3}>
                                    <label className='customLabel' htmlFor="basic-url">Data svolgimento</label>
                                    {/* Datepicker */}
                                    <Row>
                                        <Col>
                                            <label className='labelRangeCalendar'>Da</label>
                                            <DatePicker
                                                className='datepicker'
                                                closeOnScroll={true}
                                                onChange={(e) => this.props.changeDate(e, 'S')}
                                                // minDate={}
                                                maxDate={new Date()}
                                                selected={this.props.filters.find(f => f.name === 'startDataCorso').value}
                                                startDate={this.props.filters.find(f => f.name === 'startDataCorso').value}
                                                endDate={this.props.filters.find(f => f.name === 'endDataCorso').value}
                                                selectsStart
                                                showYearDropdown
                                                showMonthDropdown
                                                dateFormat="dd/MM/yyyy"
                                                locale="it"
                                            />
                                        </Col>
                                        <Col>
                                            <label className='labelRangeCalendar'>A</label>
                                            <DatePicker
                                                className='datepicker'
                                                closeOnScroll={true}
                                                onChange={(e) => this.props.changeDate(e, 'E')}
                                                selected={this.props.filters.find(f => f.name === 'endDataCorso').value}
                                                startDate={this.props.filters.find(f => f.name === 'startDataCorso').value}
                                                endDate={this.props.filters.find(f => f.name === 'endDataCorso').value}
                                                minDate={this.props.filters.find(f => f.name === 'startDataCorso').value}
                                                maxDate={new Date()}
                                                selectsEnd
                                                showYearDropdown
                                                showMonthDropdown
                                                dateFormat="dd/MM/yyyy"
                                                locale="it"
                                            />
                                        </Col>

                                    </Row>
                                </Col>
                                <Col lg={3}>
                                    <label className='customLabel' htmlFor="basic-url">Cod. Fiscale</label>
                                    <InputGroup className="mb-3" style={{ marginTop: "30px" }}>
                                        <FormControl
                                            aria-label="Default"
                                            onChange={(e) => this.props.onChangeValue(e, 'cod_fiscale')}
                                            value={this.props.filters.find((f) => f.name === 'cod_fiscale').value}
                                            placeholder="Ricerca cod. fiscale"
                                            aria-describedby="inputGroup-sizing-default"
                                        />
                                    </InputGroup>
                                </Col>
                                {/* <Col lg={3}>
                                    <Form>
                                        <label className='customLabel' htmlFor="basic-url">Studente DSA</label>
                                        <div key={`default-checkbox`} className="mb-3">
                                            <Form.Check
                                                type={'checkbox'}
                                                inline
                                                id={`default-checkbox`}
                                                label={`Si tratta di uno studente DSA`}
                                                style={{marginTop: "30px"}}
                                            />
                                        </div>
                                    </Form>
                                </Col> */}
                                <Col lg={3}>
                                    <label className='customLabel' htmlFor="basic-url">Stato import</label>
                                    <select className='form-control' onChange={(e) => this.props.onChangeValue(e, 'status_import')}
                                        value={this.props.filters.find((f) => f.name === 'status_import').value} style={{ marginTop: "30px" }}>
                                        <option></option>
                                        <option value='CONFLITTO'>CONFLITTO</option>
                                        <option value='MANCANTE'>MANCANTE</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row className='mt-2 mb-2'>
                            <Col lg={3}>
                                    <label className='customLabel' htmlFor="basic-url">Tipo Corso</label>
                                    <select className='form-control' onChange={(e) => this.props.onChangeValue(e, 'tipo_corso')}
                                        value={this.props.filters.find((f) => f.name === 'tipo_corso').value}>
                                        <option></option>
                                        <option value='TRIENNALE'>TRIENNALE</option>
                                        <option value='MAGISTRALE'>MAGISTRALE</option>
                                        <option value='C.U.'>C.U.</option>
                                    </select>
                                </Col>

                                <Col lg={3} className="mb-3">
                                    <label className='customLabel' htmlFor="basic-url">Livello</label>
                                    <select className='form-control' onChange={(e) => this.props.onChangeValue(e, 'livello')}
                                        value={this.props.filters.find((f) => f.name === 'livello').value}>
                                        <option></option>
                                        {this.props.livelli ? this.props.livelli.map(lv => <option value={lv.ID_LIVELLO}key={lv.ID_LIVELLO}>{lv.LIVELLO}</option>) : <option></option>}
                                    </select>
                                </Col>
                                <Col lg={3} className="mb-3">
                                    <label className='customLabel' htmlFor="basic-url">Tipo Esito</label>
                                    <select className='form-control' onChange={(e) => this.props.onChangeValue(e, 'tipologia_esito')}
                                        value={this.props.filters.find((f) => f.name === 'tipologia_esito').value}>
                                        <option></option>
                                        {this.props.tipiEsiti ? this.props.tipiEsiti.map(te => <option value={te.ID_TIPOLOGIA_ESITO} key={te.ID_TIPOLOGIA_ESITO}>{te.TIPOLOGIA_ESITO}</option>) : <option></option>}
                                    </select>
                                </Col>

                                {this.descriptionVisible() ?
                                    <Col lg={3} className="mb-3">
                                        <label className='customLabel' htmlFor="basic-url">Descrizione</label>
                                        <InputGroup>
                                            <FormControl
                                                aria-label="Default"
                                                onChange={(e) => this.props.onChangeValue(e, 'descrizione')}
                                                value={this.props.filters.find((f) => f.name === 'descrizione').value}
                                                placeholder="Ricerca per descrizione"
                                                aria-describedby="inputGroup-sizing-default"
                                            />
                                        </InputGroup>
                                    </Col> : null}
                            </Row>
                        </Fragment>
                    </Accordion.Collapse>

                </Accordion>
            </Col>

        </Row>)
    }
}