import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import moment from 'moment';

class ModalNotes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // user: 'Claudio Mitolo',
            timestamp: moment(this.props.notes.TIMESTAMP).format(('DD/MM/YYYY HH:mm:ss')),
            comment: this.props.notes.NOTA

        }
    }

    componentDidMount() {
        let user = this.props.notes.NOTA_USERAPP;
        let iniziali = user.split(' ');
        let primaLetteraNome = iniziali[0].substring(0, 1);
        let primaLetteraCognome = iniziali[1].substring(0, 1);

        this.setState({ primaLetteraNome: primaLetteraNome, primaLetteraCognome: primaLetteraCognome, user: user })
    }

    aggiornaCommento = () => {

    }

    render() {
        return (<Modal show={this.props.showModalNotes} onHide={this.props.closeModalNotes}>
            <Modal.Header className='stickyNotesBackground' closeButton>
                <Modal.Title style={{ color: 'white' }}>
                    NOTE
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{height: '40px'}}>
                    <span className='circularImage' title={this.state.user}><h6 style={{ color: 'white', padding: '16%' }}>{this.state.primaLetteraNome + this.state.primaLetteraCognome}</h6></span>
                    <span className='ml-2 customLabel' style={{ position: 'relative', top: '7px' }}>{this.state.timestamp}</span>
                </div>
                <div className='mt-2 p-2'>
                    <p style={{ textAlign: 'justify'}}>
                        {this.state.comment}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button onClick={this.props.closeModalNotes}>Chiudi</Button>
            </Modal.Footer>
        </Modal>)
    }
}

export default ModalNotes;