import React from 'react';
import { Button, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons'
import LoadCsv from '../sections/LoadCsv';
import DetailsCsv from '../sections/DetailsCsv';
import FinalCsv from '../sections/FinalCsv';
import papa from 'papaparse';
import axios from 'axios';
import * as config from '../config/config';
import moment from 'moment';

class ImportaEsiti extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            csvStatus: 'load',
            csvData: null,
            csvJson: null
        }


    }

    updateCsvData = (updStat, csvJson) => {
        this.setState({csvStatus: updStat, csvJson: csvJson})
    }

    handleFileSelected = (e) =>  {
        this.setState({csvData: e.target.files[0]})
    }

    reloadCsv = ()  =>  {
        this.updateCsvData('load', null);
        this.setState({csvData: null})
    }

    generateJsonFromCsv = () => {
        let self = this;
        if(this.state.csvData){
            papa.parse(this.state.csvData, {
                encoding: "UTF-8",
                complete: function(results) {
                    self.checkFields(results.data);
                    self.updateCsvData('details', results.data);
                },
                skipEmptyLines: true
            });
        }

    }

    saveData = () => {
        let param = this.state.csvToLoad
        axios.post(`${process.env.REACT_APP_API_URL}/insertValutazione`, param, this.props.authParam).then((resp) => {
            // this.setState({ matData: resp.data })
            this.setState({csvStatus: 'final'})
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    checkFields = (results) =>  {
        //call api to get id_studente
        let studenti = [];
        if(results){
            results.forEach((item, idx) =>{
                if(idx !== 0){
                    studenti.push({matricola: item[0], livello: config.encodeLivello[item[2]], tipo_esito: config.encodeTipoEsito[item[1]], descrizione: item[4]})
                }
            })
        }
        let param = {
            studenti: studenti,
            id_tipo_esito: config.encodeTipoEsito[results[1][1]]
        }
        axios.post(`${process.env.REACT_APP_API_URL}/checkData`, param, this.props.authParam).then((resp) => {
           if(resp.data[0].error){
            //casistica valutazioni già presenti
            alert(resp.data[0].errMsg)
            this.reloadCsv();
           }else {
            //casistica valutazioni non presenti
            let csvToLoad  = [];
            resp.data.forEach(el => {
                let valutazione = this.state.csvJson.filter((csv)=> csv[0] === el.matricola)[0];
                if(valutazione){
                    csvToLoad.push({
                        ID_STUDENTE: el.ID,
                        TIPOLOGIA_ESITO: config.encodeTipoEsito[valutazione[1]],
                        LIVELLO_VAL: config.encodeLivello[valutazione[2]],
                        DATA_SVOLGIMENTO: moment(valutazione[3]).format('DD/MM/YYYY'),
                        LIVELLO_PRECEDENTE: el.LIVELLO_PRECEDENTE ? el.LIVELLO_PRECEDENTE : null,
                        DESCRIZIONE: valutazione[4] ? valutazione[4] : null
                    });
                }
            })
            this.setState({csvToLoad: csvToLoad})
           }
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    goBack = () => {
        this.setParentFilters().then(() => {
            this.props.history.goBack();
        })
    }

    render() {
        return (
        <div>
            {/* <div><Button variant="primary" onClick={this.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Torna alla Home </Button></div> */}
            <Breadcrumb>
                <Breadcrumb.Item onClick={this.goBack}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                <Breadcrumb.Item active={true}>
                    Importa esiti
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className='mt-3'>
                <h3>IMPORTA ESITI</h3>
                <p>In questa sezione è possibile caricare un file esiti in formato csv, che aggiornerà i dati dello studente. Il delimitatore sarà il ";"</p>
            </div>
            <div>
                {this.state.csvStatus === 'load' ? <LoadCsv generateJsonFromCsv={this.generateJsonFromCsv} handleFileSelected={this.handleFileSelected} csvData={this.state.csvData}/> : 
                this.state.csvStatus === 'details' ? <DetailsCsv csvJson={this.state.csvJson} reloadCsv={this.reloadCsv} saveData={this.saveData}/> : 
                <FinalCsv reloadCsv={this.reloadCsv}/>}
            </div>
        </div>)
    }
}

export default ImportaEsiti;